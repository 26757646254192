import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import find from 'lodash/find';
import pick from 'lodash/pick';
import some from 'lodash/some';
import uniq from 'lodash/uniq';
import axios from 'axios';
import filter from 'lodash/filter';
import Button from '@mui/material/Button';
import CenteredLoadingIndicator from 'common/centered-loading-indicator';
import {
  COMPANION_ADDRESS,
  HUB_ADDRESS,
  MULTICALL_ADDRESS,
  SWAPPER_ADDRESS,
  SWAP_INTERVALS,
  MEAN_API_URL,
} from 'config/constants';
import { useQuery } from '@apollo/client';
import { AllowedToken, ERC20Contract, GetPairsResponseData, GetTokenListData, MulticallContract, Token, TokenType } from 'types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import getPairs from 'graphql/getPairs.graphql';
import getTokenList from 'graphql/getTokenList.graphql';
import { formatUnits } from '@ethersproject/units';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableHead from '@mui/material/TableHead';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { CallerOnlyDCAHubSwapper__factory } from '@mean-finance/dca-v2-periphery/dist';
import MULTICALLABI from 'abis/Multicall.json';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { PROTOCOL_TOKEN_ADDRESS } from 'config/tokens';
import { CallResult, ProviderSourceInput, SOURCES_METADATA, SourceId, buildSDK } from '@mean-finance/sdk';
import COMPANION_ABI from 'abis/HubCompanion.json';
import { buildEtherscanTransaction } from 'utils/etherscan';
import { useWalletClient, usePublicClient, useAccount, useNetwork, erc20ABI } from 'wagmi';
import { Address, decodeAbiParameters, encodeFunctionData, getContract, maxUint256 } from 'viem';
import { isUndefined, omit } from 'lodash';

export type TokenAddress = string;

export type Pair = {
  tokenA: TokenAddress;
  tokenB: TokenAddress;
};

export type Borrow = {
  token: TokenAddress;
  amount: BigInt;
};

export type PairIndex = {
  indexTokenA: number;
  indexTokenB: number;
};

export const DataSizes = {
  '32 KB': 32_768,
};

function assertValid(indexes: PairIndex[]) {
  for (const { indexTokenA, indexTokenB } of indexes) {
    if (indexTokenA === indexTokenB) {
      throw Error('Found duplicates in same pair');
    }
  }

  for (let i = 1; i < indexes.length; i++) {
    if (
      indexes[i - 1].indexTokenA === indexes[i].indexTokenA &&
      indexes[i - 1].indexTokenB === indexes[i].indexTokenB
    ) {
      throw Error('Found duplicates');
    }
  }
}

function getIndexes(pairs: Pair[], tokens: TokenAddress[]): PairIndex[] {
  return pairs
    .map(({ tokenA, tokenB }) => ({ indexTokenA: tokens.indexOf(tokenA), indexTokenB: tokens.indexOf(tokenB) }))
    .map(({ indexTokenA, indexTokenB }) => ({
      indexTokenA: Math.min(indexTokenA, indexTokenB),
      indexTokenB: Math.max(indexTokenA, indexTokenB),
    }))
    .sort((a, b) => a.indexTokenA - b.indexTokenA || a.indexTokenB - b.indexTokenB);
}

/** Given a list of pairs, returns a sorted list of the tokens involved */
function getUniqueTokens(pairs: Pair[]): TokenAddress[] {
  const tokenSet: TokenAddress[] = [];
  for (const { tokenA, tokenB } of pairs) {
    tokenSet.push(tokenA);
    tokenSet.push(tokenB);
  }

  return uniq([...tokenSet].sort((a, b) => a.localeCompare(b)));
}

function buildSwapInput(pairsToSwap: Pair[]): { tokens: TokenAddress[]; pairIndexes: PairIndex[] } {
  const tokens: TokenAddress[] = getUniqueTokens(pairsToSwap);
  const pairIndexes = getIndexes(pairsToSwap, tokens);
  assertValid(pairIndexes);
  return { tokens, pairIndexes };
}

interface SwapInfoPairData {
  intervalsInSwap: string;
  ratioAToB: bigint;
  ratioBToA: bigint;
  tokenA: string;
  tokenB: string;
}

interface TokenInSwapInfo {
  token: string;
  reward: bigint;
  toProvide: bigint;
  platformFee: bigint;
}

interface PairInSwapInfo {
  tokenA: string;
  tokenB: string;
  totalAmountToSwapTokenA: bigint;
  totalAmountToSwapTokenB: bigint;
  ratioAToB: bigint;
  ratioBToA: bigint;
  intervalsInSwap: string;
}

interface SwapInforTokenData {
  platformFee: bigint;
  reward: bigint;
  toProvide: bigint;
  token: Address;
}

export enum Interval {
  ONE_MINUTE = 60,
  FIVE_MINUTES = ONE_MINUTE * 5,
  FIFTEEN_MINUTES = FIVE_MINUTES * 3,
  THIRTY_MINUTES = FIFTEEN_MINUTES * 2,
  ONE_HOUR = THIRTY_MINUTES * 2,
  FOUR_HOURS = ONE_HOUR * 4,
  ONE_DAY = FOUR_HOURS * 6,
  ONE_WEEK = ONE_DAY * 7,
}

export type ExternalInterval = { id: Interval; label: string };

interface FetchedPair {
  id: string;
  tokenA: TokenAddress;
  tokenB: TokenAddress;
  activePositions: number;
  activeIntervals: ExternalInterval[];
  executableIntervals: ExternalInterval[];
}

type FetchedPairs = FetchedPair[];

export type TryMulticallResult<T> = { success: true; result: ReadonlyArray<T> } | { success: false };

const SwapsV2Admin = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const { address: account } = useAccount();
  const { chain } = useNetwork();
  const chainId = chain?.id;
  const [isLoading, setIsLoading] = useState(true);
  const [coverUpToInNative, setCoverUpToInNative] = useState<string | undefined>(undefined);
  const [tokens, setTokens] = useState<Record<string, Token>>({});
  const [isLoadingSubmitted, setIsLoadingSubmitted] = useState(false);
  const [pairsToSwap, setPairsToSwap] = useState<Pair[]>([]);
  const [isLoadingPairs, setIsLoadingPairs] = React.useState(true);
  const [pairsData, setPairData] = React.useState<FetchedPairs>([]);
  const [tokensData, setTokensData] = React.useState<GetTokenListData | null>(null);
  const [initialPairData, setInitialPairData] = useState<SwapInfoPairData[]>([]);
  const [submittedPairData, setSubmittedPairData] = useState<SwapInfoPairData[]>([]);
  const [hideCompletePairs, setHideCompletePairs] = useState(true);
  const [submittedTokendata, setSubmittedTokenData] = useState<SwapInforTokenData[]>([]);
  const [tokensWithoutAllowance, setTokensWithoutAllowance] = useState<Address[]>([]);
  const [tokenBalances, setTokenBalances] = useState<Record<string, bigint>>({});
  const [errors, setErrors] = useState<string[]>([]);
  const [swapError, setSwapError] = useState<string | null>(null);
  const [isLoadingSwapResponse, setIsLoadingSwapResponse] = React.useState(false);
  const [sdk, setSdk] = React.useState<ReturnType<typeof buildSDK<{}>>>();
  const [meanApiUrl, setMeanApiUrl] = React.useState(MEAN_API_URL);
  const [meanApiUrlInput, setMeanApiUrlInput] = React.useState(MEAN_API_URL);

  React.useEffect(() => {
    const fetchInitialPairs = async () => {
      if (!chainId) {
        return;
      }
      const pairs = await axios.get<FetchedPairs>(`${meanApiUrl}/v1/dca/networks/${chainId}/pairs`);

      setPairData(pairs.data);
      setIsLoadingPairs(false);
    };

    setIsLoadingPairs(true);
    setTokensData(null);
    fetchInitialPairs();
  }, [chainId, meanApiUrl]);

  React.useEffect(() => {
    setSdk(
      buildSDK({
        dca: {
          customAPIUrl: meanApiUrl,
        },
        provider: {
          source: {
            type: 'prioritized',
            sources: [{ type: 'public-rpcs' }],
          },
        },
        quotes: {
          defaultConfig: { global: { disableValidation: true }, custom: { squid: { integratorId: 'meanfinance-api' } } },
          sourceList: {
            type: 'overridable-source-list',
            lists: {
              default: {
                type: 'local',
              },
              overrides: [
                {
                  list: {
                    type: 'batch-api',
                    baseUri: ({ chainId }: { chainId: number }) => `${meanApiUrl}/v1/swap/networks/${chainId}/quotes/`,
                    sources: SOURCES_METADATA,
                  },
                  sourceIds: ['okx-dex', '1inch', 'uniswap', 'rango', '0x', 'changelly', 'dodo', 'barter', 'enso'],
                },
              ],
            },
          },
        },
        price: {
          source: {
            type: 'cached',
            config: {
              expiration: {
                useCachedValue: { ifUnder: '1m' },
                useCachedValueIfCalculationFailed: { ifUnder: '5m' },
              },
              maxSize: 20000,
            },
            underlyingSource: {
              type: 'defi-llama',
            },
          },
        },
      })
    );
  }, [walletClient, meanApiUrl]);

  React.useEffect(() => {
    const fetchTokens = async () => {
      if (!chainId || !sdk) {
        return;
      }
      const sdkPairs = await sdk.dcaService.getSupportedPairs({
        chains: [chainId],
      });

      const chainPairs = sdkPairs[chainId];

      const tokens = Object.values(chainPairs.tokens);

      const parsedTokens = tokens.reduce<AllowedToken[]>((acc, tokenWithVariant) => {
        const tokenVariants = tokenWithVariant.variants.map(variant => ({
          ...variant,
          ...omit(tokenWithVariant, 'variants'),
          address: variant.id,
          underlyingTokens: [],
          chainId,
          allowed: true,
          type: TokenType.Base,
        }));



        acc.push(...tokenVariants);

        return acc;
      }, []);

      setTokensData({ tokens: parsedTokens } );
    };

    fetchTokens();
  }, [sdk, chainId])

  const getNextSwapInfo = async (pairs: Pair[]) => {
    const calls = pairs.map((pair) => ({
      address: COMPANION_ADDRESS[chainId || 10],
      abi: { json: COMPANION_ABI },
      functionName: 'getNextSwapInfo',
      args: [HUB_ADDRESS[chainId || 10], [pair], true, []],
    }));

    let result: { pairs: SwapInfoPairData[]; tokens: TokenInSwapInfo[] } = { pairs: [], tokens: [] };

    try {
      const results: CallResult<{ pairs: PairInSwapInfo[]; tokens: TokenInSwapInfo[] }>[] | undefined =
        await sdk?.multicallService.tryReadOnlyMulticall({
          chainId: chainId || 10,
          calls,
          batching: { maxSizeInBytes: DataSizes['32 KB'] },
        });

      if (!results) {
        throw new Error('result is undefined');
      }

      result.pairs = results.map((result, i) =>
        result.status === 'success'
          ? result.result.pairs[0]
          : {
              tokenA: pairs[i].tokenA,
              tokenB: pairs[i].tokenB,
              totalAmountToSwapTokenA: BigInt(0),
              totalAmountToSwapTokenB: BigInt(0),
              ratioAToB: BigInt(0),
              ratioBToA: BigInt(0),
              intervalsInSwap: '0xFF', // Since we can't tell for sure, we assume that all intervals need swapping
            }
      );

      result.tokens = Object.values(
        results.reduce<Record<string, TokenInSwapInfo>>((acc, res, i) => {
          if ('failed' in result) {
            return acc;
          }

          const token0 = res.result?.tokens[0] as TokenInSwapInfo;
          const token1 = res.result?.tokens[1] as TokenInSwapInfo;

          return {
            ...acc,
            [token0.token]: {
              token: token0.token,
              platformFee: acc[token0.token] ? acc[token0.token].platformFee + token0.platformFee : token0.platformFee,
              reward: acc[token0.token] ? acc[token0.token].reward + token0.reward : token0.reward,
              toProvide: acc[token0.token] ? acc[token0.token].toProvide + token0.toProvide : token0.toProvide,
            },
            [token1.token]: {
              token: token1.token,
              platformFee: acc[token1.token] ? acc[token1.token].platformFee + token1.platformFee : token1.platformFee,
              reward: acc[token1.token] ? acc[token1.token].reward + token1.reward : token1.reward,
              toProvide: acc[token1.token] ? acc[token1.token].toProvide + token1.toProvide : token1.toProvide,
            },
          };
        }, {})
      );
    } catch (error) {
      const newErrors: string[] = [];
      pairs.forEach((pair) => {
        newErrors.push(`Error fetching nextSwapInfo for pair ${pair.tokenA}-${pair.tokenB}`);
        console.error(
          `Error fetching nextSwapInfo for pair ${pair.tokenA}-${pair.tokenB}`,
          `Called with tokens: ${JSON.stringify(tokens)}`,
          error
        );
      });

      setErrors((previousErrors) => [...previousErrors, ...newErrors]);
    }

    return result;
  };

  // const getNextSwapInfo = async (
  //   pairs: Pair[]
  // ): Promise<{ pairs: SwapInfoPairData[]; tokens: SwapInforTokenData[] }> => {
  //   const { tokens, pairIndexes } = buildSwapInput(pairs);

  //   let result: { pairs: SwapInfoPairData[]; tokens: SwapInforTokenData[] } = {pairs: [], tokens: []};
  //   try {
  //     const calls = pairs.map(({ tokenA, tokenB }) => ({
  //       target: COMPANION_ADDRESS[chainId || 10],
  //       calldata: DCAHubCompanion__factory.createInterface().encodeFunctionData('getNextSwapInfo', [HUB_ADDRESS[chainId || 10], [{ tokenA, tokenB }], true, []]),
  //       decode: [
  //         'tuple(tuple(address token,uint256 reward,uint256 toProvide,uint256 platformFee)[] tokens,tuple(address tokenA,address tokenB,uint256 totalAmountToSwapTokenA,uint256 totalAmountToSwapTokenB,uint256 ratioAToB,uint256 ratioBToA,bytes1 intervalsInSwap)[] pairs)',
  //       ],
  //     }));

  //     const multicallInstance = new ethers.Contract(
  //       MULTICALL_ADDRESS[chainId || 10],
  //       MULTICALLABI,
  //       library.getSigner() as Signer
  //     );

  //     const ABI_CODER = new AbiCoder();

  //     const contractResponse: [boolean, string][] = (await multicallInstance.callStatic.tryAggregate(
  //       false,
  //       calls.map(({ target, calldata }) => [target, calldata]),
  //     ));

  //     const mappedContractResponses: TryMulticallResult<{ pairs: PairInSwapInfo[], tokens: TokenInSwapInfo[] }>[] = contractResponse.map(([success, result], i) => (success ? { success, result: ABI_CODER.decode(calls[i].decode, result) } : { success }));

  //     console.log(mappedContractResponses);

  //     result.pairs = mappedContractResponses.map((result, i) =>
  //       result.success
  //         ? result.result[0].pairs[0]
  //         : {
  //             tokenA: pairs[i].tokenA,
  //             tokenB: pairs[i].tokenB,
  //             totalAmountToSwapTokenA: constants.Zero,
  //             totalAmountToSwapTokenB: constants.Zero,
  //             ratioAToB: constants.Zero,
  //             ratioBToA: constants.Zero,
  //             intervalsInSwap: '0xFF', // Since we can't tell for sure, we assume that all intervals need swapping
  //           }
  //     );
  //     result.tokens = Object.values(mappedContractResponses.reduce<Record<string, TokenInSwapInfo>>((acc, result, i) => {
  //       if (!result.success) {
  //         return acc;
  //       }

  //       const token0 = result.result[0].tokens[0];
  //       const token1 = result.result[0].tokens[1];

  //       return {
  //         ...acc,
  //         [token0.token]: {
  //           token: token0.token,
  //           platformFee: acc[token0.token] ? acc[token0.token].platformFee.add(token0.platformFee) : BigInt(token0.platformFee),
  //           reward: acc[token0.token] ? acc[token0.token].reward.add(token0.reward) : BigInt(token0.reward),
  //           toProvide: acc[token0.token] ? acc[token0.token].toProvide.add(token0.toProvide) : BigInt(token0.toProvide),
  //         },
  //         [token1.token]: {
  //           token: token1.token,
  //           platformFee: acc[token1.token] ? acc[token1.token].platformFee.add(token1.platformFee) : BigInt(token1.platformFee),
  //           reward: acc[token1.token] ? acc[token1.token].reward.add(token1.reward) : BigInt(token1.reward),
  //           toProvide: acc[token1.token] ? acc[token1.token].toProvide.add(token1.toProvide) : BigInt(token1.toProvide),
  //         },
  //       }
  //     }, {}
  //   ));

  //     // result = await HubContract.getNextSwapInfo(tokens, pairIndexes, true, []);
  //   } catch(error) {
  //     const newErrors :string[] = [];
  //     pairs.forEach(pair => {
  //       newErrors.push(`Error fetching nextSwapInfo for pair ${pair.tokenA}-${pair.tokenB}`)
  //       console.error(`Error fetching nextSwapInfo for pair ${pair.tokenA}-${pair.tokenB}`, `Called with tokens: ${JSON.stringify(tokens)}, pairIndexes: ${JSON.stringify(pairIndexes)}`,error)
  //     })

  //     setErrors(previousErrors => [...previousErrors, ...newErrors]);
  //   }

  //   return result;
  // };

  const getInitialNextSwapInfo = async () => {
    if (!pairsData || !tokensData) {
      return;
    }

    setIsLoading(true);

    let hubResult: any = { pairs: [], tokens: [] };

    const allowedTokens = tokensData.tokens.map((token) => token.address);
    const filteredPairs = pairsData.filter(
      (pair) => allowedTokens.includes(pair.tokenA) && allowedTokens.includes(pair.tokenB)
    );
    const fetchedResults = await getNextSwapInfo(
      filteredPairs.map((pair) => ({
        tokenA: pair.tokenA,
        tokenB: pair.tokenB,
      }))
    );

    hubResult.pairs = fetchedResults.pairs;

    setIsLoading(false);
    setInitialPairData(
      hubResult.pairs
        .map((pair: SwapInfoPairData) => ({
          intervalsInSwap: pair.intervalsInSwap,
          ratioAToB: pair.ratioAToB,
          ratioBToA: pair.ratioBToA,
          tokenA: pair.tokenA.toLowerCase(),
          tokenB: pair.tokenB.toLowerCase(),
        }))
        .sort((a: SwapInfoPairData, b: SwapInfoPairData) => parseInt(b.intervalsInSwap) - parseInt(a.intervalsInSwap))
    );
  };

  useEffect(() => {
    if (!pairsData || !tokensData) {
      return;
    }

    const allowedTokens = tokensData.tokens.map((token) => token.address);
    const filteredPairs = pairsData.filter(
      (pair) => allowedTokens.includes(pair.tokenA) && allowedTokens.includes(pair.tokenB)
    );
    setTokens(
      filteredPairs.reduce<Record<string, Token>>((acc, pair) => {
        if (!acc[pair.tokenA.toLowerCase()]) {
          const foundToken = find(tokensData.tokens, { address: pair.tokenA });
          if (foundToken) {
            acc[pair.tokenA.toLowerCase()] = foundToken;
          }
        }
        if (!acc[pair.tokenB.toLowerCase()]) {
          const foundToken = find(tokensData.tokens, { address: pair.tokenB });
          if (foundToken) {
            acc[pair.tokenB.toLowerCase()] = foundToken;
          }
        }

        return acc;
      }, {})
    );
  }, [pairsData, tokensData]);

  useEffect(() => {
    if (!walletClient) {
      console.warn('no library detected');
      return;
    }
    if (!pairsData) {
      console.warn('pairs data not loaded');
      return;
    }
    if (!tokensData) {
      console.warn('tokens data not loaded');
      return;
    }

    getInitialNextSwapInfo();
  }, [walletClient, pairsData, tokensData, chainId]);

  const getMulticallBalances = async (addresses?: Address[]): Promise<Record<string, bigint>> => {
    if (!addresses?.length || !account || !walletClient) return Promise.resolve({});

    const filteredAddresses = addresses.filter((address) => address !== PROTOCOL_TOKEN_ADDRESS);

    const balancesCall = filteredAddresses.map((address) => {
      const data = encodeFunctionData({
        abi: erc20ABI,
        functionName: 'balanceOf',
        args: [account],
      });

      return {
        target: address,
        allowFailure: true,
        callData: data,
      };
    });

    const multicallInstance = getContract({
      address: MULTICALL_ADDRESS[chainId || 10],
      abi: MULTICALLABI,
      publicClient,
      walletClient,
    });

    const results = await multicallInstance.simulate.aggregate3([balancesCall]);

    let protocolBalance: bigint | null = null;

    const hasProtocolToken = addresses.indexOf(PROTOCOL_TOKEN_ADDRESS) !== -1;

    if (addresses.indexOf(PROTOCOL_TOKEN_ADDRESS) !== -1) {
      protocolBalance = await publicClient.getBalance({
        address: account,
      });
    }

    return (results.result as unknown as { success: boolean; returnData: string }[])
      .filter(({ success }) => !!success)
      .reduce<Record<string, bigint>>(
        (acc, balanceResult, index) => ({
          ...acc,
          [filteredAddresses[index]]: BigInt(
            decodeAbiParameters([{ type: 'uint256' }], balanceResult.returnData as `0x${string}`)[0]
          ),
        }),
        {
          ...(hasProtocolToken && protocolBalance ? { [PROTOCOL_TOKEN_ADDRESS]: protocolBalance } : {}),
        }
      );
  };

  const getMulticallAllowances = async (addresses?: Address[]): Promise<Record<string, BigInt>> => {
    if (!addresses?.length || !account || !walletClient) return Promise.resolve({});

    const filteredAddresses = addresses.filter((address) => address !== PROTOCOL_TOKEN_ADDRESS);

    const balancesCall = await Promise.all(
      filteredAddresses.map((address) => {
        const data = encodeFunctionData({
          abi: erc20ABI,
          functionName: 'allowance',
          args: [account, SWAPPER_ADDRESS[chainId || 10]],
        });

        return {
          target: address,
          allowFailure: true,
          callData: data,
        };
      })
    );

    const multicallInstance = getContract({
      address: MULTICALL_ADDRESS[chainId || 10],
      abi: MULTICALLABI,
      publicClient,
      walletClient,
    });

    const results = await multicallInstance.simulate.aggregate3([balancesCall]);

    return (results.result as unknown as { success: boolean; returnData: string }[])
      .filter(({ success }) => !!success)
      .reduce<Record<Address, bigint>>(
        (acc, balanceResult, index) => ({
          ...acc,
          [filteredAddresses[index]]: BigInt(
            decodeAbiParameters([{ type: 'uint256' }], balanceResult.returnData as `0x${string}`)[0]
          ),
        }),
        {}
      );
  };

  const onVerify = async () => {
    setIsLoadingSubmitted(true);
    const hubResult = await getNextSwapInfo(pairsToSwap);

    setSubmittedPairData(
      hubResult.pairs.map((pair: SwapInfoPairData) => ({
        intervalsInSwap: pair.intervalsInSwap,
        ratioAToB: pair.ratioAToB,
        ratioBToA: pair.ratioBToA,
        tokenA: pair.tokenA.toLowerCase(),
        tokenB: pair.tokenB.toLowerCase(),
      }))
    );
    setSubmittedTokenData(
      hubResult.tokens.map((token: SwapInforTokenData) => ({
        platformFee: token.platformFee,
        reward: token.reward,
        toProvide: token.toProvide,
        token: token.token.toLowerCase() as Address,
      }))
    );

    const tokensInvolved = hubResult.tokens.reduce<Record<Address, { id: string; toProvide: BigInt }>>((acc, token) => {
      if (!(token.toProvide === BigInt(0)) && !find(acc, token.token.toLowerCase())) {
        acc[token.token.toLowerCase() as Address] = {
          id: token.token.toLowerCase(),
          toProvide: token.toProvide,
        };
      }

      return acc;
    }, {});

    const tokenKeys = Object.keys(tokensInvolved) as Address[];
    const tokensMissingApprovals: Address[] = [];
    const allowances = await getMulticallAllowances(tokenKeys);
    for (const tokenKey of tokenKeys) {
      const allowance = allowances[tokenKey];

      if (tokensInvolved[tokenKey].toProvide > allowance) {
        tokensMissingApprovals.push(tokenKey);
      }
    }
    const balances = await getMulticallBalances(tokenKeys);
    setTokenBalances(balances);
    setTokensWithoutAllowance(tokensMissingApprovals);
    setIsLoadingSubmitted(false);
  };

  const onSelectAllSwaps = () => {
    const filteredPairData = initialPairData.filter((pairData) => parseInt(pairData.intervalsInSwap, 16) !== 0);
    if (pairsToSwap.length === filteredPairData.length) {
      setPairsToSwap([]);
    } else {
      setPairsToSwap(filteredPairData.map((pair) => pick(pair, ['tokenA', 'tokenB'])));
    }
  };

  const isPairSelected = (pairData: SwapInfoPairData) => {
    return !!find(pairsToSwap, { tokenA: pairData.tokenA, tokenB: pairData.tokenB });
  };

  const handlePairClick = (pairData: SwapInfoPairData) => {
    const check = isPairSelected(pairData);
    if (!check) {
      setPairsToSwap((prevValue) => [...prevValue, pick(pairData, ['tokenA', 'tokenB'])]);
    } else {
      setPairsToSwap(
        filter(
          pairsToSwap,
          (pair) =>
            pair.tokenA.toLowerCase() !== pairData.tokenA.toLowerCase() ||
            pair.tokenB.toLowerCase() !== pairData.tokenB.toLowerCase()
        )
      );
    }
  };

  const onSwap = async () => {
    if (!walletClient) {
      return;
    }
    const SwapperContract = getContract({
      address: SWAPPER_ADDRESS[chainId || 10],
      abi: CallerOnlyDCAHubSwapper__factory.abi,
      publicClient,
      walletClient,
    });

    const { tokens, pairIndexes } = buildSwapInput(pairsToSwap);
    const minimumOutput: bigint[] = [];
    const maximumInput: bigint[] = [];
    tokens.forEach(() => {
      minimumOutput.push(BigInt(0));
      maximumInput.push(maxUint256);
    });

    try {
      const contractResponse = await SwapperContract.write.swapForCaller([
        {
          hub: HUB_ADDRESS[chainId || 10],
          tokens,
          pairsToSwap: pairIndexes,
          oracleData: '0x0',
          minimumOutput,
          maximumInput,
          recipient: account!,
          deadline: maxUint256,
        },
      ]);

      window.open(buildEtherscanTransaction(contractResponse || '', chainId || 1), '_blank');
    } catch (e) {
      console.error(e);
    }
  };

  const onDexSwap = async () => {
    if (!walletClient) {
      return;
    }
    setSwapError(null);
    setIsLoadingSwapResponse(true);
    const response = await fetch(
      `${meanApiUrl}/v1/dca/networks/${chainId}/swap`,
      // const response = await fetch(`http://localhost:6969/v1/dca/networks/${chainId}/swap`,
      {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({
          pairs: pairsToSwap,
          coverUpToInNative,
          leftoverRecipient: '0x0E71F082268Af1fD3d86E8ca4dC0Baf01FF7b381',
          sendToProvideLeftoverToHub: true,
        }),
      }
    );
    const body = await response.json();
    if (body.swapToExecute) {
      const txResponse = await walletClient.sendTransaction(body.tx);
      try {
        window.open(buildEtherscanTransaction(txResponse || '', chainId || 10), '_blank');
      } catch (e) {
        setSwapError(e as string);
        console.error(e);
      }
    } else {
      setSwapError(`Couldn't calculate a swap for the given pairs`);
    }

    setIsLoadingSwapResponse(false);
  };

  const onDexMultiSwap = onDexSwap;

  const isSamePairs = () => {
    return (
      pairsToSwap.length === submittedPairData.length &&
      !some(pairsToSwap, (pair) => !find(submittedPairData, { tokenA: pair.tokenA, tokenB: pair.tokenB }))
    );
  };

  const onApproveAll = () => {
    tokensWithoutAllowance.forEach((token) => {
      approveToken(token);
    });
  };
  const approveToken = async (token: Address) => {
    if (!walletClient) {
      return;
    }

    const erc20 = getContract({
      address: token,
      abi: erc20ABI,
      publicClient,
      walletClient,
    });

    try {
      const contractResponse = await erc20.write.approve([SWAPPER_ADDRESS[chainId || 10], maxUint256]);
      window.open(buildEtherscanTransaction(contractResponse || '', chainId || 1), '_blank');
    } catch (e) {
      console.error(e);
    }
  };

  if (isLoading || isLoadingPairs || !Object.keys(tokens).length) {
    return <CenteredLoadingIndicator />;
  }

  return (
    <Grid container alignItems="center" justifyItems="center" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3">Swap</Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h4">Total Swap info</Typography>
      </Grid>
      <Grid item xs={2}>
        <Button variant="contained" onClick={getInitialNextSwapInfo}>
          Refetch pairs
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="meanApiUrl"
          label="Mean Api Url"
          onChange={(event) => setMeanApiUrlInput(event.target.value)}
          value={meanApiUrlInput}
          defaultValue=""
        />
        <Button variant='contained' onClick={() => setMeanApiUrl(meanApiUrlInput)}>
          Change api url
        </Button>
      </Grid>

      {!!errors.length && (
        <Grid item xs={12}>
          <Alert severity="error" onClose={() => setErrors([])}>
            <AlertTitle>Error</AlertTitle>
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </Alert>
        </Grid>
      )}
      <Grid item xs={6}>
        <Card>
          <CardContent>
            {initialPairData.filter((pairData) => parseInt(pairData.intervalsInSwap, 16) !== 0).length === 0 &&
              'Nothing to execute'}
            {initialPairData
              .filter((pairData) => parseInt(pairData.intervalsInSwap, 16) !== 0)
              .map((pairData, index) => (
                <>
                  {index !== 0 && <Divider />}
                  <Typography variant="h5" component="h2">
                    Pair: {tokens[pairData.tokenA].symbol} : {tokens[pairData.tokenB].symbol}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    Intervals:{' '}
                    {SWAP_INTERVALS.filter(
                      (swapInterval) => swapInterval.key & parseInt(pairData.intervalsInSwap, 16)
                    ).reduce((acc, interval) => `${acc == '' ? '' : `${acc}, `}${interval.description}`, '')}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    Ratio A to B: {formatUnits(pairData.ratioAToB, tokens[pairData.tokenB].decimals)}{' '}
                    {tokens[pairData.tokenB].symbol}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    Ratio B to A: {formatUnits(pairData.ratioBToA, tokens[pairData.tokenA].decimals)}{' '}
                    {tokens[pairData.tokenA].symbol}
                  </Typography>
                </>
              ))}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">Pairs to execute</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox onChange={(event) => setHideCompletePairs(event.target.checked)} checked={hideCompletePairs} />
          }
          label="Hide pairs with nothing to execute"
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={pairsToSwap.length > 0 && pairsToSwap.length < initialPairData.length}
                      checked={pairsToSwap.length > 0 && pairsToSwap.length === initialPairData.length}
                      onChange={onSelectAllSwaps}
                    />
                  </TableCell>
                  <TableCell>Token A</TableCell>
                  <TableCell>Token B</TableCell>
                  <TableCell>Intervals</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {initialPairData
                  .filter((pairData) => (hideCompletePairs ? parseInt(pairData.intervalsInSwap, 16) !== 0 : true))
                  .map((pairData, index) => (
                    <TableRow
                      hover
                      onClick={() => parseInt(pairData.intervalsInSwap, 16) !== 0 && handlePairClick(pairData)}
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      selected={isPairSelected(pairData)}
                    >
                      <TableCell padding="checkbox">
                        {parseInt(pairData.intervalsInSwap, 16) !== 0 && (
                          <Checkbox color="primary" checked={isPairSelected(pairData)} />
                        )}
                      </TableCell>
                      <TableCell>{tokens[pairData.tokenA].symbol}</TableCell>
                      <TableCell>{tokens[pairData.tokenB].symbol}</TableCell>
                      <TableCell>
                        {parseInt(pairData.intervalsInSwap, 16) === 0 && 'Nothing to execute'}
                        {parseInt(pairData.intervalsInSwap, 16) !== 0 &&
                          SWAP_INTERVALS.filter(
                            (swapInterval) => swapInterval.key & parseInt(pairData.intervalsInSwap, 16)
                          ).reduce((acc, interval) => `${acc == '' ? '' : `${acc}, `}${interval.description}`, '')}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Grid>
      {!!submittedPairData.length && isSamePairs() && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h4" component="h2">
                Going to execute the following pairs:
              </Typography>
              {submittedPairData.map((pairData, index) => (
                <Typography color="textSecondary" gutterBottom key={index}>
                  {tokens[pairData.tokenA].symbol} : {tokens[pairData.tokenB].symbol}, with intervals:{' '}
                  {SWAP_INTERVALS.filter(
                    (swapInterval) => swapInterval.key & parseInt(pairData.intervalsInSwap, 16)
                  ).reduce((acc, interval) => `${acc == '' ? '' : `${acc}, `}${interval.description}`, '')}
                </Typography>
              ))}
              <Divider />
              <Typography variant="h5" component="h2">
                You need to provide:
              </Typography>
              {submittedTokendata.map((tokenData, index) =>
                tokenData.toProvide === BigInt(0) ? null : (
                  <Typography color="textSecondary" gutterBottom key={index}>
                    {formatUnits(tokenData.toProvide, tokens[tokenData.token].decimals)}{' '}
                    {tokens[tokenData.token].symbol}
                    {!isUndefined(tokenBalances[tokenData.token]) &&
                      tokenBalances[tokenData.token] >= tokenData.toProvide &&
                      ` (Got enough for swap)`}
                    {!isUndefined(tokenBalances[tokenData.token]) &&
                      tokenBalances[tokenData.token] <= tokenData.toProvide &&
                      ` (Missing ${formatUnits(
                        tokenData.toProvide - tokenBalances[tokenData.token],
                        tokens[tokenData.token].decimals
                      )})`}
                    {isUndefined(tokenBalances[tokenData.token]) && ` (Couldnt fetch balance)`}
                  </Typography>
                )
              )}
              <Typography variant="h5" component="h2">
                You will get:
              </Typography>
              {submittedTokendata.map((tokenData, index) =>
                tokenData.reward === BigInt(0) ? null : (
                  <Typography color="textSecondary" gutterBottom key={index}>
                    {formatUnits(tokenData.reward, tokens[tokenData.token].decimals)} {tokens[tokenData.token].symbol}
                  </Typography>
                )
              )}
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="coverUpToInNative"
          label="Cover up to in native"
          onChange={(event) => setCoverUpToInNative(event.target.value)}
          value={coverUpToInNative}
          defaultValue=""
        />
      </Grid>
      {!!tokensWithoutAllowance.length && (
        <Grid item xs={12}>
          <Alert severity="warning">
            The following tokens are missing approvals:{' '}
            {tokensWithoutAllowance.reduce((acc, token) => `${acc == '' ? '' : `${acc}, `}${tokens[token].symbol}`, '')}
          </Alert>
          <Button variant="contained" onClick={onApproveAll}>
            Approve All
          </Button>
        </Grid>
      )}
      <Grid item xs={3}>
        <Button variant="contained" onClick={onVerify}>
          Verify swap
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="contained"
          onClick={onSwap}
          disabled={isLoadingSubmitted || !pairsToSwap.length || !isSamePairs()}
        >
          Swap pairs
        </Button>
      </Grid>
      <Grid item xs={3}>
        {!!swapError && (
          <Grid item xs={12}>
            <Alert severity="error" onClose={() => setSwapError(null)}>
              <AlertTitle>Error</AlertTitle>
              {swapError}
            </Alert>
          </Grid>
        )}
        <Button
          variant="contained"
          onClick={onDexMultiSwap}
          disabled={isLoadingSubmitted || !pairsToSwap.length || isLoadingSwapResponse}
        >
          {isLoadingSwapResponse && <CenteredLoadingIndicator />}
          {!isLoadingSwapResponse && <>Swap pairs with dex</>}
        </Button>
      </Grid>
    </Grid>
  );
};
export default SwapsV2Admin;
